<template>  
      <!-- <div class="echart" :id="objName" :style="{float:'left',width: '100%', height: '600px'}"></div>    -->
      <div class="echart" width="100%" :id="objName" :style="style"></div>   
</template>

<script>

import echarts from  "echarts";

export default {
    props: {
          objName: {
              type: String,
              default: "echart-line"
          },
          style:{
              type:Object,
              default:{float:'left',width: '100%', height: '500px'}
          },
        //   seriesType:{
        //       type:String,
        //       default:'pie'
        //   }
      },
      methods:{

      initChart(title,subtitle,seriesName,data,seriesType="pie") {       
          let getchart = echarts.init(document.getElementById(this.objName));
        //   var ecConfig = require('echarts/config');
        //   getchart.on(ecConfig.EVENT.CLICK, eConsole);
        console.log('jinru chart',data,this.objName)

          var  option = {
               title: {
                    text: title,
                    subtext: subtitle,
                    left: 'center'
                },
                // color:['#F89616', '#5EA690', '#93C73E','#E2338B','#F48781','#FF9393'],
                color:['#E6A23C','#26A3FF','#13CE66','#FF3D3D'],
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    // orient: 'vertical',
                    // left: 'center',
                    // bottom:'bottom'
                    left: 'center',
                    top:'bottom'
                    // type: 'scroll',
                    // orient: 'vertical',
                    // right: 40,
                    // top: 40,
                    // bottom: 40,
                },
                series: [
                    {
                    name: seriesName,
                    type: seriesType,
                    radius: '50%',
                    data:data,
                    emphasis: {
                        itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                    }
                ]
            };

          getchart.setOption(option);

          //随着屏幕大小调节图表
          window.addEventListener("resize", () => {
              getchart.resize();
          });
        
        const _this = this;

        getchart.on('click',function(e){
            // console.log(e);
            var obj={objName:_this.objName,value:e.name}
            _this.$emit('onchange',obj);
            
        })
        //   

        //   this.chartEvents = {
        //         click: function(params) {
        //             console.log('params',_this.quickType,params.name+'^'+_this.filter)
        //             _this.$router.push({name: 'DataListQuick',params:{quickType: _this.quickType,quickProvince:'',quickCommon:params.name+'^'+_this.filter}});     
        //         }
        //     }

        // function eConsole(param) {    
        //     if (typeof param.seriesIndex == 'undefined') {    
        //         return;    
        //     }    
        //     if (param.type == 'click') {    
        //         alert(param.data+1);    
        //     }    
        // }



          
      },

    }

}
</script>