<template>
    
    <div class="container"   style="height:1600px;">
        <div>
            <div style="display:flex;justify-content: flex-start">
                <el-form ref="form" :model="curForm" label-width="120px" label-position="left" style="margin-top:20px;">
                    <el-row>
                        <el-col :span="22">
                            <el-form-item label="预警周期" >    
                                <!-- <el-date-picker                                
                                            v-model="curForm.dateArray"
                                            style="width:222px;"
                                            type="daterange"
                                            unlink-panels
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            :clearable="false"
                                            :shortcuts="shortcuts"
                                            @change ="handleDateChange"
                                            >
                                </el-date-picker> -->
                                <el-date-picker
                                    v-model="curForm.ym"
                                    type="month"
                                    @change ="handleDateChange"
                                    value-format="yyyy/MM"
                                    placeholder="选择月份">
                                </el-date-picker>
                            </el-form-item>  
                        </el-col>
                        <el-col :span="2">
                            <el-button type="text" @click="onExport()">导出报告</el-button>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                        <el-form-item label="媒体类型"  style="margin-left:0px;">
                            <el-select v-model="curForm.typeArray" multiple @change ="handleTypeChange" clearable  placeholder="请选择媒体类型">
                            <el-option
                                v-for="item in mediaTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                            </el-select>
                        </el-form-item>
                        </el-col>
                        <el-col :span="8">
                        <el-form-item label="在刊率区间"  style="margin-left:30px;">
                            <el-select v-model="curForm.section" @change ="handleRateChange"  placeholder="请选择在刊率区间">
                            <el-option
                                v-for="item in SectionList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                            </el-select>
                        </el-form-item>
                        </el-col>
                        <el-col :span="8">
                        <el-form-item label="统计类型"  style="margin-left:30px;">
                            <el-select v-model="curForm.rateType" @change ="handleTypeChange" clearable  placeholder="请选择媒体类型">
                            <el-option
                                v-for="item in rateType"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                            </el-select>
                        </el-form-item>
                        </el-col>
                    </el-row>
                    
                </el-form>
            </div>
            <div style="display:flex;">
                <div  style="width:40%;margin-top: 6px;background-color:#fff;border-radius:15px;height:650px;margin-left:20px;">
                    <!-- <pie-chart :title="{text:'在刊率分析'}" :chart-data="chartData" :y-label="'占比'" :chart-color="['#00B0FA']" :quick-type="'jzfxBar1'"  style="padding-top:20px;"/> -->
                    <pie-chart ref="chart_one" @onchange="onchange"/>   
                </div>
                <div style="width:59%;">
                    <div style="display:flex;">
                        <div>在刊率：{{curLabel?curLabel:'全部'}}</div>
                        <div v-if="curLabel" style="margin-left:10px;"><i class="el-icon-close" @click="onEmpty"></i></div>
                    </div>
                    <el-table
                        ref="tableObj" 
                        :data="Analysis_Alert_List.slice((currentPage-1)*pageSize,currentPage*pageSize)"
                        border stripe    
                        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
                        style="width: 100%; margin-top: 10px; font-size: x-small">                        
                        <el-table-column prop="ym" align="center" sortable label="月份"></el-table-column>
                        <el-table-column prop="station" align="center" sortable label="位置">
                            
                        </el-table-column>
                        <el-table-column prop="typeName" align="center" sortable label="媒体形式"></el-table-column>
                        <el-table-column prop="position" align="center" sortable label="套餐"></el-table-column>                        
                        <el-table-column prop="num" align="center" sortable label="资源数量"></el-table-column>
                        <el-table-column prop="useNum" align="center" sortable label="在刊数量"></el-table-column>
                        <el-table-column prop="advCnt" align="center" sortable label="客户数">
                            <template v-slot="scope">
                                <el-button v-if="scope.row.canOpen==true" type="text" @click="viewAdv(scope.row)">{{scope.row.advCnt}}</el-button>  
                                <div v-else >{{scope.row.advCnt}}</div>                                  
                            </template>
                        </el-table-column>
                        <el-table-column prop="rate" align="center" sortable label="在刊率"></el-table-column>
                    </el-table>
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="Analysis_Alert_List.length">
                    </el-pagination>

                </div>
            </div>

            <el-drawer
                :title="title"
                v-model="drawer"
                :direction="direction"
                :before-close="handleClose">
                <div  v-for='(items,index) in Analysis_Alert_EachStation'  :key="index">
                    <div style="margin-left:20px;"><i class="el-icon-menu"></i><strong>{{items.cateName}}(共{{items.adv.length}}个)</strong></div>
                    <div  style="margin-left:40px;" v-for='(subitem,index2) in items.adv'  :key="index2">
                        <span>{{subitem.advName}}</span>  
                        <span v-if="subitem.ifFree=='True'" style="color:red;font-style: italic;font-size: x-small;">赠送</span>
                    </div>
                </div>
            </el-drawer>
        
         </div>
    </div>

</template>

<script>
import {getLatestMonthDateArrayByDate,getYearMonth} from "@/utils/time";
import {getToken} from "@/utils/auth";
import { mapGetters } from "vuex";
// import Schart from 'vue-schart';
import PieChart from "@/components/PieChart";
import {Analisys_PPT_XK} from "@/api/analysis";

export default {
    components: {
        PieChart//Schart
    },
    computed: {
        ...mapGetters(["Analysis_Alert_ByType","Analysis_Alert_List","mediaTypeList","Analysis_Alert_EachStation","DownSaleReport"])
    },

    data() {
        return {
            curForm: {    
                dateArray:getLatestMonthDateArrayByDate({date: new Date(), delayNum: 2, formatValue: 'yyyy-M'}),
                typeArray:[],
                rateType:'1',
                section:'',
                ym:getYearMonth(0),
            },
            loading: true,
            chartData:[],
            curLabel:'',

            // 当前页
            currentPage: 1,
            // 每页多少条
            pageSize: 10,
            rateType:[{label:'综合上刊',value:'1'},{label:'购买上刊',value:'2'}],

            direction: 'rtl',
            drawer: false,
            title:'',
            
            SectionList:[],
        };
    },

    watch:{
            // 'Analysis_Alert_ByType'(newValue) {
            //     // var selMediaText="";
            //     // if (this.searchForm.selMedia)
            //     //     selMediaText = this.jzmtList.filter(item => this.searchForm.selMedia === item.value)[0].label;
            //     // // console.log('this.searchForm.selMedia',selMediaText,this.jzmtList)
			// 	// quShiYearChart(newValue[0],'YM','quShiYearChart',selMediaText,this);
			// },
	},

    async mounted() {
        await this.$store.dispatch('operate/mediaTypeList');
        this.onSearch();
    },

    methods:{
        handleDateChange(){  
            this.onSearch();
        },

        handleTypeChange(){
            this.onSearch();
        },

        handleRateChange(){
            this.curLabel = this.curForm.section;
            this.onSearchTable();
        },

        async viewAdv(item){
            this.title=item.station+' 上刊客户';
            await this.$store.dispatch('analysis/Analysis_Alert_EachStation',{urId:item.id}).then(()=>{
                this.drawer=true;
            })
        },

        onEmpty(){
            this.curLabel='';
            this.onSearch();
        },

        async onSearch() {
            this.loading = true;
            const {dateArray: [sYM, eYM],typeArray,rateType,ym} = this.curForm; 
            console.log('aaaaaa',sYM,ym,ym.length)       
            
            if (sYM){
                // var sDate = new Date(sYM).toLocaleDateString();
                // var eDate = new Date(eYM).toLocaleDateString();	
                var sDate;
        
                if (ym.length===undefined || ym.length>7)
                    sDate = new Date(ym).toLocaleDateString();   
                else
                    sDate=ym+'-1';
                var eDate='2000-1-1' 
                var typeIds='';
                if (typeArray){
                    typeIds = typeArray.join(',');
                }
                // console.log('selMedia',this.searchForm.selMedia)
				// await this.$store.dispatch('index/MoniMediaOwnerAnalysisByMonth',{ownerIds:this.searchForm.selMedia.join(',')});
                await this.$store.dispatch('analysis/Analysis_Alert',{sDate,eDate,typeIds,rateType}).then(()=>{
                    this.$refs.chart_one.initChart('在刊率占比分析','单位：百分比','在刊率',this.Analysis_Alert_ByType)
                    this.SectionList=[];
                    this.SectionList.push({value:'',label:'全部'});
                    this.Analysis_Alert_ByType.forEach(element => {
                        // console.log('aa',element)
                        this.SectionList.push({value:element.name,value:element.name});
                    });
                    
                })
                this.curLabel = '';
                this.onSearchTable();
                
                this.loading = false;	
            }			
		},

        async onSearchTable(){
            this.loading = true;
            const {dateArray: [sYM, eYM],typeArray,rateType,ym} = this.curForm; 
            // console.log('aaaaaa')           
            if (sYM){
                // var sDate = new Date(sYM).toLocaleDateString();
                // var eDate = new Date(eYM).toLocaleDateString();	
                var sDate = new Date(ym).toLocaleDateString();   
                var eDate='2000-1-1' 
                var typeIds='';
                if (typeArray){
                    typeIds = typeArray.join(',');
                }
                await this.$store.dispatch('analysis/Analysis_Alert_List',{sDate,eDate,typeIds,curLabel:this.curLabel,rateType}).then(()=>{
                    console.log('Analysis_Alert_List',this.Analysis_Alert_List)
                })
                
                this.loading = false;	
            }	
        },

        async onExport(){
            const {dateArray: [sYM, eYM],typeArray,rateType,ym} = this.curForm; 
            // console.log('aaaaaa')           
            if (sYM){
                // var sDate = new Date(sYM).toLocaleDateString();
                // var sDate = new Date(ym).toLocaleDateString();   
                var sDate;
                if (ym.length===undefined || ym.length>7)
                    sDate = new Date(ym).toLocaleDateString();   
                else
                    sDate=ym+'-1';
                // const loading = this.$loading({
                //     lock: true,
                //     text: '疯狂制作中...',
                //     spinner: 'el-icon-loading',
                //     background: 'rgba(0, 0, 0, 0.7)'
                // });

                // await Analisys_PPT_XK({userId: getToken(),ym:sDate,stationIds:''}).then(()=>{
                //     loading.close();
                // })
                
                await this.$store.dispatch('analysis/DownSaleReport',{ymd:sDate}).then(res=>{
                    if (this.DownSaleReport[0].fileName){
                        // let url = window.URL.createObjectURL(new Blob([this.DownSaleReport[0].fileName]));
                        // let link = document.createElement('a');
                        // link.style.display = 'none';
                        // link.href = url;
                        // link.setAttribute('download', '销控分析.pptx');
                        // document.body.appendChild(link);
                        // link.click();
                        // document.body.removeChild(link); // 下载完成移除元素
                        // window.URL.revokeObjectURL(url); // 释放掉blob对象
                         window.location = this.DownSaleReport[0].fileName;
                    }
                });
            }
           
        },

        async onchange(curLabel){
            // this.curLabel = curLabel;
            // this.onSearchTable();
            // // console.log(curLabel);
        }   ,

        handleSizeChange(val) {
            this.pageSize = val;
        },
        // 当前页
        handleCurrentChange(val) {
            this.currentPage = val;
        },
    }

}
</script>

<style>
  *{
              margin: 0;
              padding: 0;
         }
         .main{
             width: 96%;
             height: 98%;
             position: absolute;
         }
         .quarter-div{
             width: 49%;
             height: 48%;
             float: left;
         }
</style>